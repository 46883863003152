import { Auth0Provider as Auth0Configs } from "@auth0/auth0-react";
import { AuthProvider } from "context/AuthContext/AuthContext";

export const Auth0Provider = ({ children }) => {
  return (
    <Auth0Configs
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      audience={process.env.REACT_APP_AUTH0_API_AUDIENCE}
      redirectUri={window.location.origin}
      useRefreshTokens={true}
      cacheLocation={"localstorage"}
    >
      <AuthProvider>{children}</AuthProvider>
    </Auth0Configs>
  );
};
