import { createContext, useContext } from "react";
import mixpanel from "mixpanel-browser";

export const MixPanelContext = createContext({});

/**
 * A hook to get the original mixpanel instance
 * @returns {Mixpanel} the mixpanel instance from mixpanel-browser
 */
export const useMixPanel = () => useContext(MixPanelContext);

/**
 * A component to initialize the mixpanel instance
 * @param {object} props
 * @param {ReactNode} props.children
 * @param {string} [props.token='']
 * @param {object} [props.config={ ip: false }]
 */
export const MixPanelProvider = ({
  children,
  token = "",
  config = { ip: false },
}) => {
  if (!token) {
    throw new Error("Mixpanel token is required");
  }

  mixpanel.init(token, config);
  return (
    <MixPanelContext.Provider value={{ mixpanel }}>
      {children}
    </MixPanelContext.Provider>
  );
};
