import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useFetchContext } from "context/FetchContext/useFetchContext";
import { useErrorHandler } from "hooks/helpers/useErrorHandler";
import { getEvent } from "utils/requests/authedAPI";

export const fetchEvent = (slug, trainingServerAxios) => {
  return trainingServerAxios(getEvent(slug)).then(
    (response) => response.data[0]
  );
};

export const useEventData = (slug) => {
  const handleError = useErrorHandler();
  const params = useParams();
  const eventSlug = slug || params.slug;
  const { trainingServerAxios } = useFetchContext();
  const response = useQuery(
    ["event", eventSlug],
    () => fetchEvent(eventSlug, trainingServerAxios),
    {
      enabled: Boolean(eventSlug),
      onError: (err) => {
        handleError(err);
      },
    }
  );

  return response;
};
