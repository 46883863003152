import PropTypes from "prop-types";
import { Link as ReactRouterLink } from "react-router-dom";
import {
  Link as StyledTextLink,
  IconLink as StyledIconLink,
} from "components/Atoms/Elements/Link";

export const Link = ({
  children,
  href,
  isExternal,
  linkType,
  size,
  to,
  textDecoration,
  ...props
}) => {
  const StyledLink = linkType === "icon" ? StyledIconLink : StyledTextLink;
  const { isActive, ...domProps } = props;

  return (
    <StyledLink
      as={isExternal ? "a" : ReactRouterLink}
      href={href}
      rel={isExternal ? "noopener noreferrer" : undefined}
      size={size}
      target={isExternal ? "_blank" : undefined}
      to={to}
      textDecoration={textDecoration}
      {...domProps}
    >
      {children}
    </StyledLink>
  );
};

Link.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  isExternal: PropTypes.bool,
  linkType: PropTypes.oneOf(["text", "icon"]),
  size: PropTypes.oneOf(["sm", "md"]),
  textDecoration: PropTypes.string,
  // If you pass a routing library like React Router, you can pass the 'to' prop to render a link to a route
  to: PropTypes.string,
  underline: PropTypes.string,
  onClick: PropTypes.func,
};

Link.defaultProps = {
  children: null,
  href: "",
  isExternal: false,
  linkType: "text",
  onClick: null,
  size: "md",
  to: "",
  textDecoration: "underline",
  underline: "hover",
};
