/* eslint-disable no-console */
import { useCallback } from "react";
import { useFetchContext } from "context/FetchContext/useFetchContext";
import { useEventData } from "hooks/react-query/useEventData";

const API_VERSION = "api/v1";

export const createSubmission = (payload) => {
  return {
    url: `${API_VERSION}/submissions`,
    method: "post",
    data: payload,
  };
};

export const createSignatureFileURL = (payload) => {
  return {
    url: `/${API_VERSION}/attachments/upload-url`,
    method: "post",
    data: payload,
  };
};

export const getCodioFormInfoBFF = (id) => {
  return { url: `/workspace-assignment/${id}` };
};

export const getSupportDataBFF = (slug) => {
  return { url: `${API_VERSION}/programs/${slug}/support`, method: "get" };
};

export const getLandingPageData = () => {
  return {
    url: `${API_VERSION}/my-programs`,
    method: "get",
  };
};

const executeRequest = async ({ requestConfig, axiosInstance }) => {
  try {
    const config = { ...requestConfig };

    const { data } = await axiosInstance(config);
    return data;
  } catch (error) {
    throw error;
  }
};

export const useBffApi = () => {
  const { trainingBFFAxios } = useFetchContext();
  const { data: event } = useEventData();
  const { slug } = event || {};

  const assignmentsApi = {
    getAssignments: useCallback(async () => {
      return await executeRequest({
        requestConfig: {
          url: `${API_VERSION}/programs/${slug}/assignments`,
          method: "get",
        },
        axiosInstance: trainingBFFAxios,
      });
    }, [trainingBFFAxios, slug]),

    downloadSubmission: useCallback(
      async (downloadUrl) => {
        return await executeRequest({
          requestConfig: {
            url: downloadUrl,
            method: "get",
          },
          axiosInstance: trainingBFFAxios,
        });
      },
      [trainingBFFAxios]
    ),
  };

  return { assignmentsApi };
};
