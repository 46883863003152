import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { createRoot } from "react-dom/client";
import { ReactQueryDevtools } from "react-query/devtools";
import { Router } from "react-router-dom";
import { App } from "pages/App/App";
import "utils/libs/İ18next/i18n";
import { history } from "utils/constants/history";
import { Auth0Provider } from "utils/libs/Auth0/Auth0Provider";
import { ReactQueryProvider } from "utils/libs/ReactQuery/ReactQuery";
import { ErrorBoundary } from "utils/libs/Sentry/Sentry";
import { GlobalStylesWrapper as GlobalStyles } from "styles/GlobalStyles";
import { name, version } from "../package.json";
import "styles/normalize.css";

// eslint-disable-next-line no-console
console.log(
  "You are using Correlation One " +
    name +
    " version: " +
    version +
    " with stratum"
);

if (process.env.REACT_APP_ENVIRONMENT === "ephemeral") {
  localStorage.setItem("training-client-eph", window.location.href);
}

const nullLogger = {
  debug: () => {},
  info: () => {},
  warn: () => {},
  error: () => {},
};

/* eslint-disable no-console */
const logger = {
  error: console.error,
  warn: console.warn,
  info: console.info,
  debug: console.debug,
};
/* eslint-enable */

const container =
  document.getElementById("root") || document.createElement("div");
const root = createRoot(container);

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
    logger: process.env.NODE_ENV === "production" ? nullLogger : logger,
  });

  root.render(
    <LDProvider>
      <ErrorBoundary>
        <ReactQueryProvider>
          <Router history={history}>
            <GlobalStyles />
            <Auth0Provider>
              <App />
            </Auth0Provider>
            <ReactQueryDevtools initialIsOpen={false} />
          </Router>
        </ReactQueryProvider>
      </ErrorBoundary>
    </LDProvider>,
    document.getElementById("root") || document.createElement("div")
  );
})();
