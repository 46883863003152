import { forwardRef } from "react";
import styled from "styled-components/macro";
import { VFlex, HFlex } from "components/Atoms/Layouts/Flex";

const ModalPaper = forwardRef(({ children, size, ...props }, ref) => {
  return (
    <FlexContainer align={"center"} justify={"center"}>
      <StyledModalPaper ref={ref} size={size} {...props}>
        {children}
      </StyledModalPaper>
    </FlexContainer>
  );
});

export { ModalPaper };

export const FlexContainer = styled(HFlex)`
  height: 100vh;
  left: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 99;
`;

export const StyledModalPaper = styled(VFlex)`
  animation: fadeInDown 250ms ease-out;
  background-color: var(--color-neutral-white);
  border-radius: 4px;
  margin: 16px auto;
  max-height: 95vh;
  max-width: ${({ size }) => setModalSize(size)};
  overflow: auto;
  position: relative;
  width: 100%;
  z-index: 800;
  @keyframes fadeInDown {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const setModalSize = (size) => {
  switch (size) {
    case "sm":
      return "var(--media-sm)";
    case "md":
      return "600px";
    case "lg":
      return "var(--media-lg)";
    case "xl":
      return "var(--media-xl)";
    default:
      return "var(--media-md)";
  }
};
