import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { H3 } from "components/Atoms/Headings/Headings";
import { VFlex } from "components/Atoms/Layouts/Flex";
import { Text } from "components/Atoms/Text/Text";
import { Button } from "components/Blocks/Button";
import { Modal } from "components/Blocks/Modal/Modal";
import { getTraceID } from "utils/libs/Sentry/tracing";
import errorFallbackImage from "assets/error.svg";

const ErrorModal = ({
  isModalOpen,
  shouldShowCloseIcon = false,
  onClose,
  onClickBack,
}) => {
  const { t } = useTranslation("components", {
    keyPrefix: "errorFallback",
  });

  const traceID = getTraceID();

  return (
    <StyledModal
      isOpen={isModalOpen}
      shouldShowCloseIcon={shouldShowCloseIcon}
      onClose={onClose}
    >
      <Modal.Header>
        <VFlex align={"center"}>
          <img src={errorFallbackImage} alt={t("title")} />
        </VFlex>
      </Modal.Header>
      <Modal.Body>
        <VFlex gap={8} padding={"24px 0 0 "}>
          <H3 textAlign={"center"}>{t("title")}</H3>
          <Text variant="body" textAlign={"center"}>
            {t("description")}
          </Text>
          {traceID && (
            <Text variant="bodySmall" textAlign="center">
              traceID: {traceID}
            </Text>
          )}
        </VFlex>
      </Modal.Body>
      <Modal.Footer>
        <VFlex align={"center"}>
          <Button
            size={"lg"}
            label={t("back")}
            theme="secondary"
            onClick={onClickBack}
          />
        </VFlex>
      </Modal.Footer>
    </StyledModal>
  );
};

ErrorModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onClickBack: PropTypes.func.isRequired,
  shouldShowCloseIcon: PropTypes.bool,
};

const StyledModal = styled(Modal)`
  padding: 32px 24px;
`;

export { ErrorModal };
