import { createContext, useEffect, useRef, useState, useMemo } from "react";
import { useScreenSize } from "hooks/helpers/useScreenSize";
import { useSearchParams } from "utils/useQueryParam";

export const ALERT_TYPES = {
  NOT_CHROME: "NOT_CHROME",
  MOBILE: "MOBILE",
};

const WorkspaceAlertContext = createContext();

const WorkspaceAlertProvider = ({ children }) => {
  const { md, sm } = useScreenSize();
  const [params, , deleteParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  const contentRendererRef = useRef(null);

  const isNotChromium = useMemo(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return !(userAgent.includes("chrome") || userAgent.includes("chromium"));
  }, []);

  const isMobile = useMemo(() => {
    return sm || md;
  }, [sm, md]);

  useEffect(() => {
    if (
      (isMobile || isNotChromium) &&
      params?.codio_case &&
      !contentRendererRef.current
    ) {
      setIsOpen(true);
    }
  }, [sm, md, params?.codio_case, isNotChromium, isMobile]);

  const onContinue = () => {
    setIsOpen(false);
  };

  const onClose = () => {
    deleteParams("codio_case", "is_course_material");
    setIsOpen(false);
  };

  const openModal = () => {
    if (sm || md || isNotChromium) {
      setIsOpen(true);
    }
  };

  return (
    <WorkspaceAlertContext.Provider
      value={{
        isOpen,
        setIsOpen,
        onContinue,
        onClose,
        displayCodioAlert: openModal,
        isMobile,
        isNotChromium,
      }}
    >
      {children}
    </WorkspaceAlertContext.Provider>
  );
};

export { WorkspaceAlertContext, WorkspaceAlertProvider };
