import PropTypes from "prop-types";
import styled, { css } from "styled-components/macro";
import { plusIcon } from "assets/plusIcon";

const ICON_SIZING = Object.freeze({
  sm: "16px",
  md: "20px",
  lg: "24px",
  xlg: "28px",
  xxlg: "32px",
});

/* TODO: https://correlation-one.atlassian.net/browse/FEL-1113
  Once we standardize our icon implementation, refactor Icon component to avoid passing icon styling props
*/
export const Icon = ({
  color,
  icon,
  onClick,
  uiTheme,
  size,
  isInverted,
  removeStroke,
}) => {
  const containerType = onClick ? "button" : "div";

  return (
    <IconContainer
      aria-label={"icon"}
      color={color}
      data-testid={"icon"}
      as={containerType}
      onClick={(e) => (onClick ? onClick(e) : e.preventDefault())}
      isClickable={onClick}
      size={ICON_SIZING[size]}
      uiTheme={uiTheme}
      isInverted={isInverted}
      removeStroke={removeStroke}
    >
      {icon}
    </IconContainer>
  );
};

Icon.propTypes = {
  /** Enum that resizes the surface area of the icon */
  size: PropTypes.oneOf(["sm", "md", "lg", "xlg"]),
  /** Icon that gets rendered */
  icon: PropTypes.element,
  /** Function, that when provided, the component acts as a button instead of a div */
  onClick: PropTypes.func,
  /** Boolean, when true, the icon goes white and the background is filled in */
  isInverted: PropTypes.bool,
  /** Enum to theme an icon based on top level css variables */
  uiTheme: PropTypes.oneOf([
    "primary",
    "secondary",
    "success",
    "danger",
    "info",
    "warning",
    "white",
    "neutral",
  ]),
};

Icon.defaultProps = {
  size: "sm",
  icon: plusIcon,
  isInverted: false,
};

const isClickableStyling = css`
  border: none;
  cursor: pointer;
  position: relative;
  transition: 200ms easeout;
  z-index: 4;
  &:hover::before {
    background: rgba(0, 0, 0, 0.15);
    border-radius: 100px;
    content: "";
    height: ${({ size, isInverted }) =>
      `calc(${size} * ${isInverted ? 2 : 1.5})`};
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 200ms easeout;
    width: ${({ size, isInverted }) =>
      `calc(${size} * ${isInverted ? 2 : 1.5})`};
    z-index: -1;
  }
  &:active {
    transform: scale(0.97);
  }
`;

const isInvertedStyling = css`
  background: ${({ uiTheme }) => `var(--color-${uiTheme}-default)`};
  padding: 4px;
`;

const IconContainer = styled.div`
  align-items: center;
  background: transparent;
  border-radius: 100px;
  color: ${({ color }) => color};
  display: flex;
  height: fit-content;
  justify-content: center;
  pointer-events: none;
  width: fit-content;
  ${({ isInverted }) => isInverted && isInvertedStyling}
  ${({ isClickable }) => isClickable && isClickableStyling}
  svg {
    height: ${({ size }) => size};
    stroke: ${({ uiTheme, isInverted, removeStroke }) =>
      isInverted
        ? "var(--color-neutral-white)"
        : uiTheme
        ? `var(--color-${uiTheme}-default)`
        : removeStroke
        ? "none"
        : "currentColor"};
    width: ${({ size }) => size};
  }
`;
