import { css } from "styled-components/macro";

export const BORDER_RADIUS_RESET_STYLES = {
  top: `
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  `,
  right: `
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  `,
  bottom: `
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `,
  left: `
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  `,
};

export const SIZE_STYLES = {
  sm: css`
    max-height: 32px;
    padding: ${({ shouldRenderIconOnly }) =>
      shouldRenderIconOnly ? "5px" : "5px 16px"};
    .button-text {
      font-size: 14px;
      line-height: 22px;
    }
    .flex-container {
      gap: 7px;
    }
  `,

  lg: css`
    max-height: 48px;
    padding: ${({ shouldRenderIconOnly }) =>
      shouldRenderIconOnly ? "12px" : "12px 24px"};

    .button-text {
      font-size: 16px;
      line-height: 24px;
    }
    .flex-container {
      gap: 4px;
    }
  `,
};

export const ICON_POSITION_STYLES = {
  left: css`
    .flex-container {
      flex-direction: row;
    }
  `,

  right: css`
    .flex-container {
      flex-direction: row-reverse;
    }
  `,
};

export const THEME_STYLES = {
  primary: css`
    background: var(--color-secondary-default);
    .button-text {
      color: var(--color-neutral-black);
    }
    svg {
      color: var(--color-neutral-black);
    }
    :hover {
      background: var(--color-secondary-dark);
    }
    :disabled {
      background: var(--color-neutral-95);
    }
  `,

  secondary: css`
    background: var(--color-neutral-white);
    border: 1px solid var(--color-primary-dark);
    .button-text {
      color: var(--color-primary-dark);
    }
    svg {
      color: var(--color-primary-dark);
    }
    :hover {
      background: var(--color-primary-dark-translucent);
    }
    :disabled {
      background: var(--color-neutral-white);
      border: 1px solid var(--color-neutral-53);
    }
  `,

  tertiary: css`
    background: none;
    .button-text {
      color: var(--color-primary-dark);
    }
    svg {
      color: var(--color-primary-dark);
    }
    :hover {
      text-decoration-line: underline;
      text-decoration-color: var(--color-primary-dark);
      background: ${({ shouldRenderIconOnly }) =>
        shouldRenderIconOnly && "var(--color-primary-dark-translucent)"};
    }
    :disabled {
      text-decoration-line: none;
    }
  `,

  destructive: css`
    background: var(--color-danger-default);
    .button-text {
      color: var(--color-neutral-white);
    }
    svg {
      color: var(--color-neutral-white);
    }
    :hover {
      background: var(--color-danger-dark);
    }
    :disabled {
      background: var(--color-neutral-95);
    }
  `,
};

export const BaseStyles = css`
  align-items: center;
  border: 0;
  border-radius: 24px;
  cursor: pointer;
  display: flex;
  .button-text {
    font-weight: 600;
    white-space: nowrap;
  }
  :disabled {
    cursor: auto;
    .button-text {
      color: var(--color-neutral-23);
    }
    svg {
      color: var(--color-neutral-23);
    }
  }
`;
