import { Children, useRef } from "react";
import PropTypes from "prop-types";
import { createPortal } from "react-dom";
import styled from "styled-components/macro";
import { Overlay } from "components/Atoms/Overlay/Overlay";
import { Button } from "components/Blocks/Button";
import {
  ModalBody,
  ModalPaper,
  ModalFooter,
  ModalHeader,
} from "components/Blocks/Modal/components";
import { useOnClickOutside } from "hooks/helpers/useOnClickOutside";
import { closeIcon } from "assets/closeIcon";

const Modal = ({
  isOpen,
  isOutsideClickEnabled,
  size,
  onClose,
  children,
  shouldShowCloseIcon,
  ...props
}) => {
  const modalRef = useRef();
  useOnClickOutside({ ref: modalRef, handler: onClose, isOutsideClickEnabled });

  if (!isOpen) return null;

  return createPortal(
    <>
      <Overlay isOpen={isOpen} />
      <ModalPaper {...props} ref={modalRef} size={size} role={"dialog"}>
        {children}
        {shouldShowCloseIcon && (
          <ModalIconContainer>
            <Button
              onClick={onClose}
              theme={"tertiary"}
              icon={closeIcon}
              size={"lg"}
              ariaLabel={"modal-close"}
            />
          </ModalIconContainer>
        )}
      </ModalPaper>
    </>,
    document.body
  );
};

Modal.propTypes = {
  /**
   * Indicates whether the modal should be displayed.
   */
  isOpen: PropTypes.bool,
  /**
   * This prop allows you to detect clicks outside of a the Modal. If
   * you pass this prop, it closes the modal when any element outside
   * of the modal is clicked.
   */
  isOutsideClickEnabled: PropTypes.bool,
  /**
   * The Modal comes in 5 sizes. Available options are: `xs`, `sm`, `md`, `lg`, `xl`
   */
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  /**
   * Indicates what should happen before closing the modal.
   */
  onClose: PropTypes.func,
  children: (props, propName, componentName) => {
    const allowedChildren = ["ModalHeader", "ModalBody", "ModalFooter"];
    const prop = props[propName];

    let error = null;
    Children.forEach(prop, (child) => {
      if (!allowedChildren.includes(child.type?.name)) {
        error = new Error(
          `\`${componentName}\` children should be one of \`ModalHeader\`, \`ModalBody\` or \`ModalFooter\`.`
        );
      }
    });
    return error;
  },

  shouldShowCloseIcon: PropTypes.bool,
};

Modal.defaultProps = {
  isOpen: false,
  isOutsideClickEnabled: false,
  size: "md",
  onClose: () => {},
  shouldShowCloseIcon: true,
};

Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;

export { Modal };

export const ModalIconContainer = styled.div`
  padding: 8px;
  position: absolute;
  right: 8px;
  top: 5px;
`;
