import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components/macro";

export const Link = styled(RouterLink)`
  color: var(--color-primary-dark);
  cursor: pointer;
  font-size: ${({ size }) => (size === "sm" ? "14px" : "16px")};
  font-weight: var(--weight-regular);
  letter-spacing: 0em;
  line-height: ${({ size }) => (size === "sm" ? "20px" : "24px")};
  text-decoration: ${({ textDecoration }) => textDecoration};
`;

export const IconLink = styled(RouterLink)`
  color: var(--color-primary-dark);
  cursor: pointer;
  & svg {
    height: ${({ size }) => (size === "sm" ? "24px" : "48px")};
    vertical-align: bottom;
    width: ${({ size }) => (size === "sm" ? "24px" : "48px")};
  }
`;

export const HeaderLogoLink = styled(Link)`
  align-items: center;
  display: flex;
  height: 100%;
  ${({ to }) => to === null && "pointer-events: none"}
`;
