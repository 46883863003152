export const getCodioFormInfo = (id) => {
  return { url: `/codio-cases/${id}`, method: "get" };
};

export const getEvent = (slug) => {
  return { url: `/events/?slug=${slug}`, method: "get" };
};

export const getEvents = () => {
  return { url: "events/", method: "get" };
};

export const getSubmissionRequests = (eventId) => {
  return { url: `submissions/requests/?event=${eventId}`, method: "get" };
};

/**
 * Get teaching assistant profile information from a list of ids
 * @param {number[]} idList list of teaching assistant's id to get the profiles
 * @param {number} idList event id to get specific data from
 * @returns the list of teaching assistant's user info corresponding to those ids
 */
export const getTeachingAssistantProfiles = (idList, eventId) => {
  const queryEvent = eventId ? `&event_id=${eventId}` : "";
  const idListString = idList.toString();
  return {
    url: `teaching-assistants?id=${idListString}${queryEvent}`,
    method: "get",
  };
};

export const postSubmissionSigned = (data) => {
  return {
    url: "submissions/submissions/",
    method: "post",
    data,
  };
};

export const patchSubmissionSigned = (fileId, data) => {
  return {
    url: `submissions/submissions/${fileId}/`,
    method: "patch",
    data,
  };
};

export const logout = () => {
  return { url: "logout", method: "post" };
};

export const loginToDiscourse = (eventId, data) => {
  return { url: `discourse/${eventId}/sso`, method: "post", data };
};

export const workspaceRedirect = (data) => {
  return { url: "workspace/sso", method: "post", data };
};

export const getAssignmentsData = (eventId) => {
  return { url: `/programs/${eventId}/assignments`, method: "get" };
};

export const subscribeToCalendarRequest = (eventSlug) => {
  return { url: `/calendars/${eventSlug}/sync`, method: "post" };
};

export const getSupportData = (slug) => {
  return { url: `/programs/${slug}/support`, method: "get" };
};
