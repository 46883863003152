import { useState } from "react";

/* Note:
  Error boundaries do not catch errors for:
    - Event handlers (learn more)
    - Asynchronous code (e.g. setTimeout or requestAnimationFrame
       callbacks)
    - Server side rendering
    - Errors thrown in the error boundary itself (rather than its children)
  Reference: https://reactjs.org/docs/error-boundaries.html
  
  So to catch errors in these conditions we can use this hook
  This allows developers to use error boundaries to catch errors that
  wouldn’t otherwise be caught by traditional React error boundaries.
  This means we can now use error boundaries to catch errors during API
  requests, event handlers, and other parts of code where errors could
  occur.
*/
export const useErrorHandler = (givenError) => {
  const [error, setError] = useState(null);

  if (givenError != null) throw givenError;
  if (error != null) throw error;
  return setError;
};
