import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { CFlex } from "components/Atoms/Layouts/Flex";
import {
  BaseStyles,
  SIZE_STYLES,
  ICON_POSITION_STYLES,
  THEME_STYLES,
  BORDER_RADIUS_RESET_STYLES,
} from "components/Blocks/Button/Styles";
import { Icon } from "components/Blocks/Icon/Icon";

const Button = ({
  ariaLabel,
  isDisabled,
  icon,
  iconPosition,
  label,
  onClick,
  theme,
  size,
  type,
  removeBorderRadius,
  removeIconStroke,
  id,
  className,
}) => {
  return (
    <StyledButton
      aria-label={ariaLabel}
      disabled={isDisabled}
      iconPosition={iconPosition}
      onClick={onClick}
      shouldRenderIconOnly={icon && !label}
      theme={theme}
      size={size}
      type={type}
      removeBorderRadius={removeBorderRadius}
      /* TODO: https://correlation-one.atlassian.net/browse/FEL-1113
        Once we standardize our icon implementation, refactor Button component to avoid passing icon styling props
      */
      removeIconStroke={removeIconStroke}
      id={id}
      className={className}
    >
      <CFlex className={"flex-container"}>
        {icon && (
          <Icon icon={icon} size={size} removeStroke={removeIconStroke} />
        )}
        {label && <span className="button-text">{label}</span>}
      </CFlex>
    </StyledButton>
  );
};

export { Button };

Button.propTypes = {
  ariaLabel: PropTypes.string,
  isDisabled: PropTypes.bool,
  icon: PropTypes.element,
  iconPosition: PropTypes.oneOf(Object.keys(ICON_POSITION_STYLES)),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClick: PropTypes.func,
  theme: PropTypes.oneOf(Object.keys(THEME_STYLES)),
  size: PropTypes.oneOf(Object.keys(SIZE_STYLES)),
  type: PropTypes.string,
  removeBorderRadius: PropTypes.arrayOf(
    PropTypes.oneOf(["top", "right", "bottom", "left"])
  ),
  removeIconStroke: PropTypes.bool,
  id: PropTypes.string,
  className: PropTypes.string,
};

Button.defaultProps = {
  ariaLabel: "",
  isDisabled: false,
  icon: null,
  iconPosition: "left",
  label: "",
  onClick: () => null,
  theme: "primary",
  size: "sm",
  type: "button",
  removeBorderRadius: [],
  removeIconStroke: false,
};

const StyledButton = styled.button`
  ${BaseStyles}
  ${({ theme }) => THEME_STYLES?.[theme]}
  ${({ size }) => SIZE_STYLES?.[size]}
  ${({ iconPosition }) => ICON_POSITION_STYLES?.[iconPosition]}
  ${({ removeBorderRadius }) =>
    removeBorderRadius.map((side) => BORDER_RADIUS_RESET_STYLES?.[side])}
`;
