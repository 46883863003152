import styled, { css } from "styled-components/macro";

const baseStyles = css`
  color: var(--color-primary-default);
  font-family: var(--font-family);
  letter-spacing: 0em;
  margin: 0;
  padding: 0;
  text-transform: capitalize;
`;

const overideStyles = css`
  color: ${({ color, uiTheme }) =>
    color ? color : uiTheme && `var(--color-${uiTheme}-default)`};
  font-weight: ${({ weight }) => weight && `var(--weight-${weight})`};
  text-align: ${({ textAlign }) => textAlign};
  text-transform: ${({ textTransform }) => textTransform};
`;

export const H1 = styled.h1`
  ${baseStyles}
  font-size: 36px;
  font-weight: var(--weight-bold);
  line-height: 54px;
  @media (max-width: 900px) {
    font-size: 28px;
    line-height: 44px;
  }
  ${overideStyles}
`;

export const H2 = styled.h2`
  ${baseStyles}
  font-size: 32px;
  font-weight: var(--weight-medium);
  line-height: 48px;
  @media (max-width: 900px) {
    font-size: 24px;
    line-height: 36px;
  }
  ${overideStyles}
`;

export const H3 = styled.h3`
  ${baseStyles}
  font-size: 28px;
  font-weight: var(--weight-medium);
  line-height: 42px;
  @media (max-width: 900px) {
    font-size: 22px;
    line-height: 34px;
  }
  ${overideStyles}
`;

export const H4 = styled.h4`
  ${baseStyles}
  font-size: 24px;
  font-weight: var(--weight-medium);
  line-height: 36px;
  @media (max-width: 900px) {
    font-size: 20px;
    line-height: 32px;
  }
  ${overideStyles}
`;

export const H5 = styled.h5`
  ${baseStyles}
  font-size: 20px;
  font-weight: var(--weight-medium);
  line-height: 30px;
  @media (max-width: 900px) {
    font-size: 18px;
    line-height: 30px;
  }
  ${overideStyles}
`;

export const H6 = styled.h6`
  ${baseStyles}
  font-size: 16px;
  font-weight: var(--weight-bold);
  line-height: 24px;
  @media (max-width: 900px) {
    font-size: 16px;
    line-height: 24px;
  }
  ${overideStyles}
`;
