import styled from "styled-components/macro";

export const SVG = styled.svg.attrs(() => ({
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none",
  viewBox: "0 0 24 24",
  strokeWidth: "2",
  strokeLinecap: "round",
  strokeLinejoin: "round",
}))`
  align-items: center;
  display: flex;
  justify-content: center;
`;
