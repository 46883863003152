import { createContext, useCallback, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import { useHistory } from "react-router-dom";
import { Loader } from "components/Blocks/Loader/Loader";

export const AuthContext = createContext();
const { Provider } = AuthContext;

const AuthProvider = ({ children }) => {
  const history = useHistory();
  const {
    isAuthenticated: isAuthenticatedByAuth0,
    user,
    getAccessTokenSilently,
    logout,
    isLoading,
    getIdTokenClaims,
  } = useAuth0();

  const access = localStorage.getItem("access") || "";
  const refresh = localStorage.getItem("refresh") || "";
  const [authState, setAuthState] = useState({
    access,
    refresh,
  });

  const getAuth0Token = useCallback(async () => {
    const token = await getAccessTokenSilently().then((token) => token);
    const idToken = await getIdTokenClaims().then((claims) => claims.__raw);

    localStorage.setItem("access", token);
    localStorage.setItem("idToken", idToken);

    setAuthState((prevState) => ({
      ...prevState,
      access: token,
      idToken,
    }));

    return token;
  }, [getAccessTokenSilently, getIdTokenClaims]);

  useEffect(() => {
    user && getAuth0Token();

    return () => setAuthState({});
  }, [user, getAuth0Token]);

  const setAuthInfo = ({ access = "", refresh = "", idToken = "" }) => {
    access && localStorage.setItem("access", access);
    refresh && localStorage.setItem("refresh", refresh);
    idToken && localStorage.setItem("idToken", idToken);

    setAuthState({
      access,
      refresh,
      idToken,
    });
  };

  const handleLogout = () => {
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    localStorage.removeItem("idToken");
    Sentry.setUser(null);
    if (user) {
      logout({
        returnTo: window.location.origin,
      });
    } else {
      history.push("/login");
    }
  };

  return (
    <Loader isLoading={isLoading}>
      <Provider
        value={{
          auth0User: user,
          authState,
          getAuth0Token,
          isAuthenticatedByAuth0,
          authLogout: handleLogout,
          setAuthState: (authInfo) => setAuthInfo(authInfo),
        }}
      >
        {children}
      </Provider>
    </Loader>
  );
};

export { AuthProvider };
