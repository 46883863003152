import { useContext } from "react";
import { FetchContext } from "context/FetchContext/FetchContext";

export const useFetchContext = () => {
  const context = useContext(FetchContext);

  if (!context) {
    throw new Error("FetchContext must be used within a FetchProvider");
  }

  return context;
};
