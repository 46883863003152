export const TRAINING_SERVER_AXIOS_CONFIG = {
  baseURL: process.env.REACT_APP_API_URL,
};

export const TRAINING_BFF_AXIOS_CONFIG = {
  baseURL: process.env.REACT_APP_BFF_URL,
};

export const OUTCOMES_CLOUD_FUNCTIONS = {
  hasJobURL: process.env.REACT_APP_HAS_JOB_CF_URL,
};
