import { useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { VFlex } from "components/Atoms/Layouts/Flex";
import { ErrorModal } from "components/Blocks/ErrorBoundary/ErrorModal";
import { routes } from "utils/constants/routes";

const ErrorFallback = () => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const { resetBoundary } = useErrorBoundary();

  const history = useHistory();

  return (
    <LayoutContainer align={"center"} justify={"center"}>
      <VFlex justify={"center"} align={"center"} gap={"16"}>
        <ErrorModal
          isModalOpen={isModalOpen}
          onClickBack={() => {
            resetBoundary();
            history.push(routes.HOME);
          }}
          onClose={() => {
            resetBoundary();
            setIsModalOpen(false);
          }}
        />
      </VFlex>
    </LayoutContainer>
  );
};

export { ErrorFallback };

const LayoutContainer = styled(VFlex)`
  background: var(--color-neutral-white);
  color: var(--color-neutral-white);
  height: 100%;
  min-height: 100vh;
  position: relative;
  text-align: center;
`;
