import { createContext } from "react";
import { useSupportData } from "hooks/react-query/useSupportData";

const HelpbarContext = createContext();
const { Provider } = HelpbarContext;

const HelpbarProvider = ({ children }) => {
  const { data: supportData, isLoading: isSupportDataLoading } =
    useSupportData();

  const {
    teaching_assistant_support: taSupport,
    career_success_coach_support: cscSupport,
  } = supportData || {};

  const tasData = taSupport?.profiles;
  const cscData = cscSupport?.profiles;

  const hasCSCData = cscData?.length > 0;
  const hasTAData = tasData?.length > 0;
  const taRoundRobin = taSupport?.round_robin_link;
  const cscRoundRobin = cscSupport?.round_robin_link;
  const isTASupportActive = taSupport?.is_support_active;
  const isCSCSupportActive = cscSupport?.is_support_active;
  const hasRoundRobinData = taRoundRobin || cscRoundRobin;
  const isDataAvailable = hasTAData || hasCSCData || hasRoundRobinData;
  const isTaDataValid = (hasTAData || taRoundRobin) && isTASupportActive;
  const isCscDataValid = (hasCSCData || cscRoundRobin) && isCSCSupportActive;
  const supportIsActive = isTASupportActive || isCSCSupportActive;

  const shouldShowButton =
    supportIsActive && (hasTAData || hasCSCData || hasRoundRobinData);

  const isHelpBarActive =
    supportIsActive && isDataAvailable && (isTaDataValid || isCscDataValid);

  return (
    <Provider
      value={{
        supportData,
        isSupportDataLoading,
        shouldShowButton,
        isHelpBarActive,
        isTaDataValid,
        isCscDataValid,
      }}
    >
      {children}
    </Provider>
  );
};

export { HelpbarContext, HelpbarProvider };
