import { QueryClient, QueryClientProvider } from "react-query";

/**
 * Cache only once approach
 * Reference: https://medium.com/in-the-weeds/fetch-a-query-only-once-until-page-refresh-using-react-query-a333d00b86ff
 */
const cacheTimeInMs = 1000 * 60 * 60 * process.env.REACT_APP_CACHE_TIME;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: cacheTimeInMs,
      cacheTime: cacheTimeInMs,
    },
  },
});

export const ReactQueryProvider = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};
