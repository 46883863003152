import styled, { css } from "styled-components/macro";

export const MaxWidthContainer = styled.div`
  height: 100%;
  margin: 0 auto;
  max-width: 1260px;
  width: 100%;
`;

export const NumberContainer = styled.div`
  align-items: baseline;
  background: var(--color-primary-translucent);
  border-radius: 100px;
  display: flex;
  justify-content: center;
  max-height: 22px;
  max-width: 22px;
  min-height: 22px;
  min-width: 22px;
  padding-right: 0px;
  padding-top: 2px;
`;

export const ListContainer = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const paddingStyles = css`
  ${({ p }) => p && `padding: var(--space-${p}px);`}
  ${({ pt }) => pt && `padding-top: var(--space-${pt}px);`}
  ${({ pb }) => pb && `padding-bottom: var(--space-${pb}px);`}
  ${({ pl }) => pl && `padding-left: var(--space-${pl}px);`}
  ${({ pr }) => pr && `padding-right: var(--space-${pr}px);`}
  ${({ py }) =>
    py &&
    `padding-top: var(--space-${py}px);
  padding-bottom: var(--space-${py}px);`}
  ${({ px }) =>
    px &&
    `padding-left: var(--space-${px}px);
  padding-right: var(--space-${px}px);`}
`;

const overideStyles = css`
  ${({ bottom }) => bottom && `bottom: ${bottom}px;`}
  ${({ margin }) => margin}
  ${({ right }) => right && `right: ${right}px;`}
  ${({ left }) => left && `left: ${left}px;`}
  ${({ top }) => top && `top: ${top}px;`}
  z-index: ${({ zIndex }) => (zIndex >= 0 ? zIndex : 10)};
  ${paddingStyles}
`;

export const Box = styled.div`
  ${paddingStyles}
`;

export const Container = styled(Box)`
  height: 100%;
  width: 100%;
`;

export const FitContentContainer = styled.div`
  width: fit-content;
`;

export const FixedContainer = styled.div`
  position: fixed;
  ${overideStyles}
`;

export const RelativeContainer = styled.div`
  position: relative;
  ${overideStyles};
`;

export const AbsoluteContainer = styled.div`
  position: absolute;
  ${overideStyles}
  ${({ horizontalCentered }) =>
    horizontalCentered && "left: 50%; transform: translateX(-50%);"}
  ${({ verticalCentered }) =>
    verticalCentered && "top: 50%; transform: translateY(-50%);"}
`;

const hideScrollbarStyles = css`
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ScrollContainer = styled.div`
  border-radius: ${({ borderRadius }) => borderRadius || "0"};
  max-height: ${({ maxHeight }) => maxHeight};
  overflow-x: ${({ overflowX }) => overflowX || "auto"};
  overflow-y: ${({ overflowY }) => overflowY || "auto"};
  ${({ hideScrollbar }) => hideScrollbar && hideScrollbarStyles}
  ${overideStyles}
`;

const slideStyles = (isDisplayed) => `
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  transition: transform 250ms ease-out;  transform: ${
    isDisplayed ? "translateY(0%)" : "translateY(100%)"
  };
`;

const slideDisplay = (isDisplayed) => `
  display: ${isDisplayed ? "block" : "none"};
`;

export const VerticalSlider = styled.div`
  z-index: 15;
  ${({ shouldAnimateDesktop, shouldAnimateMobile, isDisplayed }) => {
    if (shouldAnimateDesktop && shouldAnimateMobile) {
      return css`
        ${slideStyles(isDisplayed)}
      `;
    } else if (shouldAnimateMobile) {
      return css`
        @media (max-width: 900px) {
          ${slideStyles(isDisplayed)}
        }
        @media (min-width: 901px) {
          ${slideDisplay(isDisplayed)}
        }
      `;
    } else if (shouldAnimateDesktop) {
      return css`
        @media (min-width: 901px) {
          ${slideStyles(isDisplayed)}
        }
        @media (max-width: 900px) {
          ${slideDisplay(isDisplayed)}
        }
      `;
    }
  }}
`;
