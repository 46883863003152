import { createContext, useState } from "react";
/**
 * This context is meant to communicate changes in the LD context
 * that way the components are aware that something is pending to
 * be updated from the LD side. *
 */

const LDStatusContext = createContext();
const { Provider } = LDStatusContext;

const LDStatusProvider = ({ children }) => {
  const [isLDContextUpdating, setIsLDContextUpdating] = useState(true);
  return (
    <Provider value={{ isLDContextUpdating, setIsLDContextUpdating }}>
      {children}
    </Provider>
  );
};

export { LDStatusContext, LDStatusProvider };
