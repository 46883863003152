import * as Sentry from "@sentry/react";
import axios from "axios";

const AxiosController = (
  axiosConfig,
  accessToken,
  getAuth0Token,
  authLogout
) => {
  const axiosInstance = axios.create(axiosConfig);
  const idToken = localStorage.getItem("idToken");
  const span = Sentry.getActiveSpan();
  const traceparent = span ? span.toTraceparent() : null;

  axiosInstance.interceptors.request.use(
    (config) => {
      config.headers["X-C1-USER-ID-TOKEN"] = idToken;
      if (!config._retry) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error || "");
    }
  );
  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error?.config;

      // Did a request, failed with 401 (token expired), we should do the
      // request again with a new token
      if (error?.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const newAccessToken = await getAuth0Token();
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        originalRequest.headers["X-C1-USER-ID-TOKEN"] = idToken;
        originalRequest.headers.traceparent = traceparent;
        return axiosInstance(originalRequest);
      }

      // Already retried the request and it's still returning 401
      // we should logout the user (credentials not valid)
      if (error?.response?.status === 401 && originalRequest._retry) {
        authLogout();
      }

      return Promise.reject(error || "");
    }
  );

  return axiosInstance;
};

export { AxiosController };
